import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query{
      mainImage: file(relativePath: { eq: "kids-paused.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 350) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <div class="headerImg">
        <Img fluid={data.mainImage.childImageSharp.fluid} alt="Storywars Kids" className="" />
      </div>
      <h1>We’re Taking a Break</h1>
      <p className="panel">We’ve taken the difficult decision of taking a hiatus while we re-think, re-imagine and consider where to go next.</p>
      <p className="bold">A big thank you to everyone that has contributed. <br/>Keep on writing y’all!</p>
    </Layout>
  )
}

export default IndexPage
